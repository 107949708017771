<template>
  <div class="modal fade show" v-if="modalVisible">
    <div class='modal__backdrop'></div>
    <div class="modal-dialog modal-xl zindex-2 modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header bg-light">
          <h5 class="modal-title" id="exampleModalLabel">
            # {{ deposit.code || '-' }} · {{ $t('f_management.deposit_details_modal_title') }}
          </h5>
          <button type="button" class="close" @click="closeModal()">
            <i aria-hidden="true" class="ki ki-close font-size-h4"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="row d-flex flex-wrap px-2">
            <div class="col-md-6 col-sm-12">
              <div class="row">
                <p class="col-md-4 col-sm-12">{{ $t('commons.name') }} {{ $t('commons.surname') }}:</p>
                <b class="col-md-8 col-sm-12">{{ userFullName }}</b>
              </div>
              <div class="row mt-2">
                <p class="col-md-4 col-sm-12">{{ $t('c_management.identity_no') }}:</p>
                <b class="col-md-8 col-sm-12">{{ user.identity_number }}</b>
              </div>
              <div class="row mt-2">
                <p class="col-md-4 col-sm-12">{{ $t('commons.cost') }}:</p>
                <b class="col-md-8 col-sm-12">{{ deposit.amount_formatted }}</b>
              </div>
              <div class="row mt-2">
                <p class="col-md-4 col-sm-12">{{ $t('commons.status') }}:</p>
                <b class="col-md-8 col-sm-12">
                  <span class="badge" :class="statusClass">
                    {{ statusLabel }}
                  </span>
                </b>
              </div>
              <div class="row mt-2" v-if="+deposit.status === 40">
                <p class="col-md-4 col-sm-12">{{ $t('f_management.reason_for_cancellation') }}:</p>
                <b class="col-md-8 col-sm-12">{{ deposit.reason || '-' }}</b>
              </div>
              <div class="row mt-2">
                <p class="col-md-4 col-sm-12">{{ $t('f_management.date_request') }}:</p>
                <b class="col-md-8 col-sm-12">{{ deposit.created_at }}</b>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="row">
                <p class="col-md-4 col-sm-12">{{ $t('f_management.transaction_number') }}:</p>
                <b class="col-md-8 col-sm-12">{{ deposit.code || '-' }}</b>
              </div>
              <div class="row mt-2">
                <p class="col-md-4 col-sm-12">{{ $t('f_management.payment_method') }}:</p>
                <b class="col-md-8 col-sm-12 text-break">{{ getDepositProviderInfos(deposit).name }}</b>
              </div>
              <template v-if="isCrypto">
                <div class="row mt-2">
                  <p class="col-md-4 col-sm-12">{{ $t('finance.wallet_address') }}:</p>
                  <div class="d-flex flex-row col-md-8 col-sm-12">
                    <b class="text-truncate">{{ wallet.address || '-' }}</b>
                    <i class="fa fa-copy ml-1 cursor-pointer" v-if="wallet.address" @click="copyText(wallet.address)"></i>
                  </div>
                </div>
                <div class="row mt-2">
                  <p class="col-md-4 col-sm-12">Tx id:</p>
                  <div class="d-flex flex-row col-md-8 col-sm-12">
                    <b class="text-truncate">{{ deposit.txhash || '-' }}</b>
                    <i class="fa fa-copy ml-1 cursor-pointer" v-if="deposit.txhash" @click="copyText(deposit.txhash)"></i>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="row mt-2">
                  <p class="col-md-4 col-sm-12">{{ $t('finance.bank') }}:</p>
                  <div class="d-flex flex-row col-md-8 col-sm-12">
                    <b class="text-truncate">{{ getDepositProviderInfos(deposit).bank }}</b>
                    <i class="fa fa-copy ml-1 cursor-pointer" @click="copyText(getDepositProviderInfos(deposit).bank)"
                      v-if="getDepositProviderInfos(deposit).bank">
                    </i>
                  </div>
                </div>
                <div class="row mt-2">
                  <p class="col-md-4 col-sm-12">IBAN:</p>
                  <div class="d-flex flex-row col-md-8 col-sm-12">
                    <b class="text-truncate" ref="providerAddress">
                      {{ getDepositProviderInfos(deposit).address }}
                    </b>
                    <i class="fa fa-copy ml-1 cursor-pointer" @click="copyText(getDepositProviderInfos(deposit).address)"
                      v-if="getDepositProviderInfos(deposit).address">
                    </i>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <template v-if="isPendingOperationApproval && checkPermission('crm.finance.deposit.modal.action')">
            <input class="form-control col-md-3 col-sm-12" type="text" :placeholder="$t('commons.description')"
              :title="$t('commons.description')" v-model="detailsModalActionForm.missing_amount_desc" />
            <currency-input class="form-control col-md-3 col-sm-12" :placeholder="$t('f_management.missing_amount')"
              :title="$t('f_management.missing_amount')" :precision="2" v-model="detailsModalActionForm.missing_amount" />
            <button type="button" class="btn btn-success font-weight-bold" @click.prevent="approveDeclineProcess('1')">
              {{ $t('merchant.btn_approve') }}
            </button>
            <button type="button" class="btn btn-danger font-weight-bold" @click.prevent="approveDeclineProcess('2')">
              {{ $t('merchant.btn_decline') }}
            </button>
          </template>
          <template v-if="isPendingFinancialApproval && checkPermission('crm.finance.deposit.finance-modal.action')">
            <button type="button" class="btn btn-success font-weight-bold" @click.prevent="approveDeclineFinance('1')">
              {{ $t('merchant.btn_approve') }}
            </button>
            <button type="button" class="btn btn-danger font-weight-bold" @click.prevent="approveDeclineFinance('2')">
              {{ $t('merchant.btn_decline') }}
            </button>
          </template>
          <button type="button" class="btn btn-outline-secondary" @click="closeModal()" data-dismiss="modal">
            {{ $t('merchant.btn_close') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {},
  data() {
    return {};
  },
  components: {},
  computed: {
    ...mapState({
      permissions: (state) => state.customer.permissions,
      modalVisible: (state) => state.depositTransaction.detailsModalVisible,
      deposit: (state) => state.depositTransaction.detailsModalData.deposit,
      defaultCurrency: (state) => state.depositTransaction.detailsModalData.defaultCurrency,
      detailsModalActionForm: state => state.depositTransaction.detailsModalActionForm
    }),
    user() {
      return this.deposit?.user || {};
    },
    userFullName() {
      const { name, surname } = this.user;
      if (name && surname) return `${name} ${surname}`
      return '-';
    },
    statusClass() {
      switch (this.deposit?.status) {
        case 10:
          return 'badge-warning';
        case 20:
        case 70:
          return 'badge-light';
        case 30:
          return 'badge-success';
        case 40:
        case 50:
        case 60:
          return 'badge-danger';
        default:
          return '';
      }
    },
    statusLabel() {
      if (this.deposit?.status) {
        return this.$t(`f_management.statuses.${this.deposit.status}`)
      }
      return '-'
    },
    isPendingOperationApproval() {
      return (+this.deposit?.status === 10) || false
    },
    isPendingFinancialApproval() {
      return (+this.deposit?.status === 60) || false
    },
    isCrypto() {
      return this.defaultCurrency?.crypto || false;
    },
    wallet() {
      return this.deposit?.wallet || { address: '' };
    },
  },
  methods: {
    closeModal() {
      this.$store.commit('depositTransaction/TOGGLE_DETAILS_MODAL', false);
    },
    approveDeclineProcess(action) {
      this.$store.dispatch('depositTransaction/DETAILS_MODAL_SMS', { id: this.deposit.id, action })
    },
    approveDeclineFinance(action) {
      this.$store.dispatch('depositTransaction/FINANCE_SMS', { id: this.deposit.id, action })
    },
    checkPermission(permission) {
      let status = false;
      this.permissions?.forEach(function (item) {
        if (item.name == permission) {
          status = true;
        }
      });
      return status
    },
    getDepositProviderInfos(deposit) {
      return {
        name: deposit?.provider?.name || "-",
        bank: deposit?.provider?.bank || "-",
        address: deposit?.provider?.address || "-"
      }
    },
    async copyText(text) {
      try {
        await navigator.clipboard.writeText(text);
        this.$toast.info(this.$t("copied_to_clipboard"));
      } catch (error) {
        this.$toast.error(this.$t('service.fatal_error'));
      }
    }
  },
  created() { },
};
</script>
<style scoped>
.show {
  display: block;
}

.modal__backdrop {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
</style>